import { useFetcher, useLocation } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { LocaleLink } from './locale-link.tsx'

export const LAST_UPDATED_DATE = new Date('2/19/2024').valueOf()

export function TOSBanner() {
	const { pathname, search } = useLocation()
	const { t } = useTranslation(['common'])
	const fetcher = useFetcher()

	return (
		<div className="pointer-events-none fixed inset-x-0 bottom-0 z-50 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
			<div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-primary-500 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
				<p className="mb-4 mr-4 text-sm text-white sm:mb-0">
					<strong className="font-semibold">{t('cookies.cookie')}</strong>
					<svg
						viewBox="0 0 2 2"
						className="mx-2 inline h-0.5 w-0.5 fill-current"
						aria-hidden="true"
					>
						<circle cx={1} cy={1} r={1} />
					</svg>
					{t('cookies.title')}{' '}
					<LocaleLink
						target="_blank"
						rel="noreferrer"
						route="termsOfService"
						className="inline text-gray-300 transition-colors duration-200 hover:text-white"
					>
						{t('cookies.termsOfService')}
					</LocaleLink>{' '}
					{t('cookies.and')}{' '}
					<LocaleLink
						target="_blank"
						rel="noreferrer"
						route="privacy"
						className="inline text-gray-300 transition-colors duration-200 hover:text-white"
					>
						{t('cookies.privacyPolicy')}
					</LocaleLink>
				</p>
				<fetcher.Form method="post" action="/hide-tos-banner" className="flex">
					<input hidden name="redirectUrl" value={pathname + search} readOnly />
					<button className="rounded border border-white bg-transparent px-4 py-2 text-white transition-colors duration-200 hover:bg-white hover:text-black">
						{t('cookies.close')}
					</button>
				</fetcher.Form>
			</div>
		</div>
	)
}
