/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from '@remix-run/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOptionalUser } from '#app/utils/user.ts'

const languageMappings: { [key: string]: string[] } = {
	en: [
		'en',
		'en-US',
		'en-GB',
		'en-AU',
		'en-CA',
		'en-IN',
		'en-NZ',
		'en-ZA',
		'en-IE',
		'en-JM',
		'en-BB',
		'en-BS',
		'en-BZ',
		'en-TT',
		'en-NG',
		'en-GH',
		'en-KE',
		'en-UG',
		'en-ZM',
		'en-ZW',
		'en-PH',
		'en-SG',
		'en-HK',
		'en-MY',
		'en-MT',
		'en-FJ',
		'en-PK',
		'en-LR',
		'en-SL',
		'en-GM',
		'en-GY',
	],
	nl: ['nl', 'nl-NL', 'nl-BE', 'nl-SR', 'nl-AW', 'nl-CW', 'nl-SX'],
}

export function getLanguageCode(browserLanguage: string): string {
	let browserLanguageCode = browserLanguage.includes('-')
		? browserLanguage.split('-')[0]
		: browserLanguage

	let languageCode = Object.keys(languageMappings).find(
		lang =>
			languageMappings[lang].includes(browserLanguage) ||
			languageMappings[lang].includes(browserLanguageCode),
	)

	return languageCode || 'en'
}

export function useLanguageRedirect(currentLocale: string) {
	let { i18n } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const user = useOptionalUser()
	const languageCode = getLanguageCode(currentLocale)
	const currentLanguage = i18n.language

	useEffect(() => {
		if (location.pathname === '/') {
			i18n.changeLanguage(languageCode)
			if (user) {
				navigate(`/${languageCode}/chat`)
			} else {
				navigate(`/${languageCode}`)
			}
		}
	}, [currentLanguage, i18n, languageCode, location.pathname, navigate])
}
